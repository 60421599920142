import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  FormControl,
  InputLabel,
  Tab,
  Box,
  Typography,
  Tabs,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import dayjs from "dayjs";
import axios from "axios";

import InputAdornment from "@mui/material/InputAdornment";
import { _TRIP_PATH } from "../../Config/Constants";

const AddTrip = ({
  singleTrip,
  handleEdit,
  handleDelete,
  getTripList,
  triptypeList,
  showForm,
  formAction,
  supervisor,
  handleFormShow,
  viewProject,
  viewProfile,
}) => {
  const [tripForm, settripForm] = useState({
    supervisor: "",
    project: "",
    tripType: "",
    travelType: "",
    fromDate: "",
    toDate: "",
    returnFlightTicket: "",
    description: "",
    onwardJourney: "",
    returnJourney: "",
    visaExpiry: "",
    insuranceExpiry: "",
    equipments: "",
    address:"",
    poContact:"",
    alarm: "On",
    TripId: "",
    country: "",
    tripComment: "",
    invoiceCondition: "",
    invoiceAmount: "",
    invoiceCurrency: "",
    po: "",
    invoiceCountry: "",
    invoiceComment: "",
    salaryCondition: "",
    salary: "",
    salaryCurrency: "",
    dailyAllowance: "",
    dailyAllowanceCurrency: "",
    drivingAllowance: "",
    overTime:"",
    overTimeCurrency: "",
    drivingAllowanceCurrency: "",
    salaryComment: "",
  });
  const [projectList, setProjectList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [supervisorDefault, setSupervisorDefaulf] = useState("");
  const [projectDefault, setProjectDefault] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openCntry,setopenCntry]=useState(false);
  const [cntryText,setcntryText]=useState("");
  const [displayDetails,setDisplayDetails]=useState(true);
  const [isLoading,setIsLoading]=useState(false);
  React.useEffect(() => {

    if (formAction == "add" && supervisor && supervisor.supervisorName) {
      console.log("xxxxx 1",supervisor );
      let supervisorObj = {
        value: parseInt(supervisor.supervisor),
        label: supervisor.supervisorName+" "+supervisor.supervisorSurname,
      };
      setSupervisorList([{ ...supervisorObj }]);
      settripForm({ ...tripForm, supervisor: { ...supervisorObj } });
    } else if (singleTrip.id && formAction == "edit") {
      console.log("xxxxx 2",supervisor );
      let project = {
        value: parseInt(singleTrip.project),
        label: singleTrip.projectName,
      };
      let supervisorObj = singleTrip.supervisorName? {
        value: parseInt(singleTrip.supervisor),
        label: singleTrip.supervisorName,
      }:{};
      let countryLs = {
        value: parseInt(singleTrip.country),
        label: singleTrip.countryName || "",
      };
      let invoiceCntry = {
        value: parseInt(singleTrip.invoiceCountry),
        label: singleTrip.invoiceCountryName || "",
      };
      setSupervisorList([{ ...supervisorObj }]);
      setCountryList([{ ...countryLs }, { ...invoiceCntry }]);
      setProjectList([{ ...project }]);
      settripForm({
        ...singleTrip,
        supervisor: { ...supervisorObj },
        project: { ...project },
        country: { ...countryLs },
        invoiceCountry: { ...invoiceCntry },
        onwardJourney:Number(singleTrip.onwardJourney)?singleTrip.onwardJourney:"",
        returnJourney:Number(singleTrip.returnJourney)?singleTrip.returnJourney:"",
        visaExpiry:singleTrip.visaExpiry!="0000-00-00"?singleTrip.visaExpiry:""
      });
    } else {
      console.log("xxxxx 3",supervisor );
      settripForm({...tripForm,...singleTrip});
      handleClearForm();
    }
  }, [singleTrip, formAction, supervisor]);
  React.useEffect(() => {
    console.log("tripFormtripForm", singleTrip);
  }, [tripForm]);
  const fetchProjectList = (e) => {
    axios
      .get(_TRIP_PATH + "/getProject?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.projectList.map((data) => ({
          value: parseInt(data.id),
          label: data.projectName,
          clientName: data.clientName,
        }));
        setProjectList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCountryList = (e) => {
    setcntryText(e.target.value);
    axios
      .get(_TRIP_PATH + "/getCountry?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.countryList.map((data) => ({
          value: parseInt(data.id),
          label: data.nicename,
        }));
        setCountryList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPmList = (e, item) => {
    console.log(e.target.value);
    axios
      .get(_TRIP_PATH + "/getSupervisor?search=" + e.target.value)
      .then((response) => {
        let cl = response.data.empList.map((data) => ({
          value: parseInt(data.id),
          label: data.name,
        }));
        if (item == "supervisor") setSupervisorList(cl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitForm = () => {
    let param = { ...tripForm };
    setIsLoading(true);
    param["supervisor"] = param["supervisor"].value
      ? param["supervisor"].value
      : 0;
    //  param['TripId']=param['TripId'].value?param['TripId'].value:0;
    param["project"] = param["project"].value ? param["project"].value : 0;
    param["country"] = param["country"].value ? param["country"].value : 0;
    param["invoiceCountry"] = (param["invoiceCountry"]&&param["invoiceCountry"].value)? param["invoiceCountry"].value: 0;

    axios
      .post(_TRIP_PATH + "/addTrip", param)
      .then((response) => {
        if(response.data?.status=='error'){
          alert(response.data.msg);
          setIsLoading(false);
        }else{
          getTripList();
          handleClearForm();
        }
        //setIsLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleClearForm = () => {
    settripForm({
      supervisor: "",
      project: "",
      tripType: "",
      travelType: "",
      fromDate: "",
      toDate: "",
      returnFlightTicket: "",
      description: "",
      onwardJourney: "",
      returnJourney: "",
      visaExpiry: "",
      insuranceExpiry: "",
      equipments: "",
      address:"",
      poContact:"",
      alarm: "On",
      TripId: "",
      country: "",
      tripComment: "",
      invoiceCondition: "",
      invoiceAmount: "",
      invoiceCurrency: "",
      po: "",
      invoiceCountry: "",
      invoiceComment: "",
      salaryCondition: "",
      salary: "",
      salaryCurrency: "",
      dailyAllowance: "",
      dailyAllowanceCurrency: "",
      overTime:"",
      overTimeCurrency:"",
      drivingAllowance: "",
      drivingAllowanceCurrency: "",
      salaryComment: "",
    });
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
           {children}
          </Box>
        )}
      </div>
    );
  }
useEffect(()=>{
  console.trace(tripForm)
},[tripForm])
  return (
    <>
      {showForm ? (
        <>
          <Grid container>
            <Grid item sm={8}>
              <h4 className={"triphead"}>
                {formAction != "add" ? "Update" : "Create"} Trip
              </h4>
            </Grid>
          </Grid>
          <Grid item sm={12}>


            <div
              aria-label="basic tabs example"
            >
              <span  onClick={()=> setDisplayDetails(true)} className={displayDetails?"_selected_tab _tr_cstab":" _tr_cstab"} > Details</span>
              <span   onClick={()=> setDisplayDetails(false)}  className={!displayDetails?"_selected_tab _tr_cstab":" _tr_cstab"} > Terms & Conditions</span>
            </div>
            <div>
              {displayDetails?
            <div value={tabValue} index={0}>
              <p  key={"supKeyVals3P"}>
                <Autocomplete
                  size="small"
                  key={"supKeyVals3"}
                  options={supervisorList}
                  value={tripForm.supervisor}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => {
                    return option.label || "";
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setTimeout(fetchPmList(e, "supervisor"),500) }
                      label="Employee Name"
                      key={"supKeyVals"}
                    />
                  )}
                  onChange={(e, newValue) => {
                    settripForm({
                      ...tripForm,
                      supervisor: newValue,
                    });
                  }}
                />
              </p>

              <p>
                <Autocomplete
                  size="small"
                 id="projectCl"
                  options={projectList}
                  value={tripForm.project}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => {
                    return option.label || "";
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={fetchProjectList}
                      label="Project Name"
                    />
                  )}
                  onChange={(e, newValue) => {
                    settripForm({
                      ...tripForm,
                      project: newValue,
                    });
                  }}
                />
              </p>
              {/* {JSON.stringify(projectList)}
        {tripForm.project&&tripForm.project?.value?<p>
          {projectList.find(p=>p.value==tripForm.project.value)?projectList.find(p=>p.id==tripForm.project.value).clientName:"xx"}
        </p>:null} */}
              <p>
                <Autocomplete
                  size={"small"}
                  sx={{ width: 300 }}
                  options={countryList}
                  value={tripForm.country}
                  disableClearable={true}
                  getOptionLabel={(option) => {
                    return option.label || "";
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={fetchCountryList}
                      label="Country"
                      placeholder="Search  Country"
                      
                    />
                  )}
                  onChange={(e, newValue) => {
                    settripForm({
                      ...tripForm,
                      country: newValue,
                    });
                  }}
                />
              </p>
              <p>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel>Trip Type</InputLabel>
                  <Select
                    size="small"
                    label="Trip Type"
                    value={tripForm.tripType}
                    fullWidth={true}
                    onChange={(e) => {
                      settripForm({
                        ...tripForm,
                        tripType: e.target.value,
                      });
                    }}
                  >
                    {triptypeList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: item.color,
                            marginRight: "15px",
                          }}
                        ></span>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </p>
              <p>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel>Travel Type</InputLabel>

                  <Select
                    size="small"
                    label="Travel Type"
                    value={tripForm.travelType}
                    fullWidth={true}
                    onChange={(e) => {
                      settripForm({
                        ...tripForm,
                        travelType: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={"Local"}>Local</MenuItem>
                    <MenuItem value={"Domestic"}>Domestic</MenuItem>
                    <MenuItem value={"International"}>International</MenuItem>
                  </Select>
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="From Date"
                  variant="outlined"
                  value={tripForm.fromDate ? tripForm.fromDate : " "}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      fromDate: e.target.value,
                    });
                  }}
                  type="date"
                />
                </FormControl>
              </p>

              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Return Date"
                  variant="outlined"
                  value={tripForm.toDate ? tripForm.toDate : " "}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      toDate: e.target.value,
                    });
                  }}
                  type="date"
                />
                </FormControl>
              </p>

              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Return Flight Tickets"
                  variant="outlined"
                  value={tripForm.returnFlightTicket ? tripForm.returnFlightTicket : " "}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      returnFlightTicket: e.target.value,
                    });
                  }}
                  type="date"
                />
                </FormControl>
              </p>

              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Onward Journey Days"
                  variant="outlined"
                  value={tripForm.onwardJourney}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      onwardJourney: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Return Journey Days"
                  variant="outlined"
                  value={tripForm.returnJourney}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      returnJourney: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Visa Expiry Date"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  value={tripForm.visaExpiry ? tripForm.visaExpiry : " "}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      visaExpiry: e.target.value,
                    });
                  }}
                  type="date"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Insurance Expiry Date"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> </InputAdornment>
                    ),
                  }}
                  value={
                    tripForm.insuranceExpiry ? tripForm.insuranceExpiry : " "
                  }
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      insuranceExpiry: e.target.value,
                    });
                  }}
                  type="date"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Address"
                  variant="outlined"
                  value={tripForm.address}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      address: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Po Contact"
                  variant="outlined"
                  value={tripForm.poContact}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      poContact: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Travel Equipments"
                  variant="outlined"
                  value={tripForm.equipments}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      equipments: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Travel Descritption"
                  variant="outlined"
                  multiline
                  rows={2}
                  value={tripForm.description}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      description: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  multiline
                  rows={2}
                  label="Comments"
                  variant="outlined"
                  value={tripForm.tripComment}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      tripComment: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>

              <p>
                <label>Notification : </label>
              
                <ButtonGroup
                  disableElevation
                  aria-label="Disabled elevation buttons"
                >
                  <Button
                    variant={tripForm.alarm == "On" ? "contained" : "outlined"}
                    onClick={(e) => {
                      settripForm({
                        ...tripForm,
                        alarm: "On",
                      });
                    }}
                  >
                     On 
                  </Button>
                  <Button
                    variant={tripForm.alarm == "Off" ? "contained" : "outlined"}
                    onClick={(e) => {
                      settripForm({
                        ...tripForm,
                        alarm: "Off",
                      });
                    }}
                  >
                    Off
                  </Button>
                </ButtonGroup>
              </p>
            </div>
          :
              <div value={tabValue} index={1}>
              <strong>Invoice</strong>
              <p>
                
                <FormControl size="small" sx={{ width: 300 }}>
                  <InputLabel>Invoicing conditions</InputLabel>
                  <Select
                    
                    label="Travel Type"
                    value={tripForm.invoiceCondition}
                    fullWidth={true}
                    onChange={(e) => {
                      settripForm({
                        ...tripForm,
                        invoiceCondition: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={"Hourly"}>Hourly</MenuItem>
                    <MenuItem value={"Daily"}>Daily</MenuItem>
                    <MenuItem value={"Weekly"}>Weekly</MenuItem>
                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </p>
              <p>
                
                <Grid container>
                  <Grid item >
                    <TextField
                      size="small"
                      fullWidth={true}
                      label="Amount"
                      variant="outlined"
                      sx={{ width: 170 }} 
                      value={tripForm.invoiceAmount}
                      onChange={(e) => {
                        settripForm({
                          ...tripForm,
                          invoiceAmount: e.target.value,
                        });
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item  style={{ paddingLeft: "15px" }}>
                    <FormControl sx={{ width: 110 }}   size="small">
                      <InputLabel>Currency </InputLabel>
                      <Select
                      
                        label="Currency"
                        value={tripForm.invoiceCurrency}
                        fullWidth={true}
                        onChange={(e) => {
                          settripForm({
                            ...tripForm,
                            invoiceCurrency: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"AUD"}>AUD</MenuItem>
                        <MenuItem value={"DKK"}>DKK</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                        <MenuItem value={"NOK"}>NOK</MenuItem>
                        <MenuItem value={"SEK"}>SEK</MenuItem>
                        <MenuItem value={"CNY"}>CNY</MenuItem>
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </p>
              <p>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="PO"
                  variant="outlined"
                  sx={{ width: 300 }} 
                  value={tripForm.po}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      po: e.target.value,
                    });
                  }}
                  type="text"
                />
              </p>
              <p>
                <Autocomplete
                  disablePortal
                 
                  size={"small"}
                  sx={{ width: 300 }}
                  options={countryList}
                  value={tripForm.invoiceCountry}
                  getOptionLabel={(option) => {
                    return option.label || "";
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={fetchCountryList}
                      label="Invoice Country"
                      placeholder="Search  Country"
                    />
                  )}
                  onChange={(e, newValue) => {
                    settripForm({
                      ...tripForm,
                      invoiceCountry: newValue,
                    });
                  }}
                />
              </p>
              <p>
                <TextField
                  size="small"
                  fullWidth={true}
                  multiline
                  rows={2}
                  sx={{ width: 300 }} 
                  label="Invoice comments"
                  variant="outlined"
                  value={tripForm.invoiceComment}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      invoiceComment: e.target.value,
                    });
                  }}
                  type="text"
                />
              </p>
              <strong>Salary</strong>
              <p>
                <FormControl size="small" sx={{ width: 300 }}>
                  <InputLabel>Salary conditions </InputLabel>
                  <Select
                    
                    label="Salary conditions"
                    value={tripForm.salaryCondition}
                    fullWidth={true}
                    onChange={(e) => {
                      settripForm({
                        ...tripForm,
                        salaryCondition: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={"Hourly"}>Hourly</MenuItem>
                    <MenuItem value={"Daily"}>Daily </MenuItem>
                    <MenuItem value={"Weekly"}>Weekly</MenuItem>
                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                     
                  </Select>
                </FormControl>
              </p>
              <p>
                <Grid container>
                  <Grid item >
                    <TextField
                      size="small"
                      fullWidth={true}
                      label="Salary"
                      sx={{ width: 170 }} 
                      variant="outlined"
                      value={tripForm.salary}
                      onChange={(e) => {
                        settripForm({
                          ...tripForm,
                          salary: e.target.value,
                        });
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item style={{ paddingLeft: "15px" }}>
                    <FormControl size="small" sx={{ width: 110 }}>
                      <InputLabel>Currency </InputLabel>
                      <Select
                        
                        label="Currency"
                        value={tripForm.salaryCurrency}
                        fullWidth={true}
                        onChange={(e) => {
                          settripForm({
                            ...tripForm,
                            salaryCurrency: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"AUD"}>AUD</MenuItem>
                        <MenuItem value={"DKK"}>DKK</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                        <MenuItem value={"NOK"}>NOK</MenuItem>
                        <MenuItem value={"SEK"}>SEK</MenuItem>
                        <MenuItem value={"CNY"}>CNY</MenuItem>
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </p>
              <p>
                <Grid container>
                  <Grid item >
                    <TextField
                      size="small"
                      fullWidth={true}
                      label="Daily allowance"
                      variant="outlined"
                      sx={{ width: 170 }} 
                      value={tripForm.dailyAllowance}
                      onChange={(e) => {
                        settripForm({
                          ...tripForm,
                          dailyAllowance: e.target.value,
                        });
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item style={{ paddingLeft: "15px" }}>
                    <FormControl  size="small" sx={{ width: 110 }}>
                      <InputLabel>Currency </InputLabel>
                      <Select
                       
                        label="Currency"
                        value={tripForm.dailyAllowanceCurrency}
                        fullWidth={true}
                        onChange={(e) => {
                          settripForm({
                            ...tripForm,
                            dailyAllowanceCurrency: e.target.value,
                          });
                        }}
                      > <MenuItem value={"AUD"}>AUD</MenuItem>
                        <MenuItem value={"DKK"}>DKK</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                        <MenuItem value={"NOK"}>NOK</MenuItem>
                        <MenuItem value={"SEK"}>SEK</MenuItem>
                        <MenuItem value={"CNY"}>CNY</MenuItem>
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </p>
              <p>
                <Grid container>
                  <Grid item >
                    <TextField
                      size="small"
                      fullWidth={true}
                      label="Driving allowance"
                      sx={{ width: 170 }} 
                      variant="outlined"
                      value={tripForm.drivingAllowance}
                      onChange={(e) => {
                        settripForm({
                          ...tripForm,
                          drivingAllowance: e.target.value,
                        });
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item  style={{ paddingLeft: "15px" }}>
                    <FormControl  size="small" sx={{ width: 110 }}>
                      <InputLabel>Currency </InputLabel>
                      <Select
                       
                        label="Currency"
                        value={tripForm.drivingAllowanceCurrency}
                        fullWidth={true}
                        onChange={(e) => {
                          settripForm({
                            ...tripForm,
                            drivingAllowanceCurrency: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"AUD"}>AUD</MenuItem>
                        <MenuItem value={"DKK"}>DKK</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                        <MenuItem value={"NOK"}>NOK</MenuItem>
                        <MenuItem value={"SEK"}>SEK</MenuItem>
                        <MenuItem value={"CNY"}>CNY</MenuItem>
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </p>
              <p>
                <Grid container>
                  <Grid item >
                    <TextField
                      size="small"
                      fullWidth={true}
                      label="Overtime"
                      sx={{ width: 170 }} 
                      variant="outlined"
                      value={tripForm.overTime}
                      onChange={(e) => {
                        settripForm({
                          ...tripForm,
                          overTime: e.target.value,
                        });
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item  style={{ paddingLeft: "15px" }}>
                    <FormControl  size="small" sx={{ width: 110 }}>
                      <InputLabel>Currency </InputLabel>
                      <Select
                       
                        label="Currency"
                        value={tripForm.overTimeCurrency}
                        fullWidth={true}
                        onChange={(e) => {
                          settripForm({
                            ...tripForm,
                            overTimeCurrency: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"AUD"}>AUD</MenuItem>
                        <MenuItem value={"DKK"}>DKK</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                        <MenuItem value={"NOK"}>NOK</MenuItem>
                        <MenuItem value={"SEK"}>SEK</MenuItem>
                        <MenuItem value={"CNY"}>CNY</MenuItem>
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </p>
              <p>
              <FormControl sx={{ width: 300 }}>
                <TextField
                  size="small"
                  fullWidth={true}
                  multiline
                  rows={2}
                  label="Comments"
                  variant="outlined"
                  value={tripForm.salaryComment}
                  onChange={(e) => {
                    settripForm({
                      ...tripForm,
                      salaryComment: e.target.value,
                    });
                  }}
                  type="text"
                />
                </FormControl>
              </p>
              
            </div>
}
            </div>
          <Grid item sm={12} style={{textAlign:"center"}}>
           
          <Button onClick={handleSubmitForm} variant="contained" disabled={!tripForm.project||!tripForm.country||!tripForm.tripType||!tripForm.travelType
          ||!tripForm.fromDate|| !tripForm.toDate||isLoading}>
              {formAction != "add" ? "Update" : "Create"}
            </Button>
            <Button onClick={() => handleFormShow()} variant="outline">
              Cancel
            </Button>
          </Grid>
          </Grid>
          
        </>
      ) : (
        <>
          {singleTrip.supervisor ? (
            <div className="_tr_display">
              <Tabs
            value={tabValue}
            onChange={(e, val) => setTabValue(val)}
            aria-label="basic tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Terms & Conditions" {...a11yProps(1)} />
          </Tabs>
               <TabPanel value={tabValue} index={0}>
              <p>
                Employee Name :{" "}
                <strong
                  onClick={() => viewProfile(singleTrip.supervisor)}
                  className={"hasAction"}
                >
                  {singleTrip.supervisorName} {singleTrip.supervisorSurname} 
                </strong>
              </p>
              <p>
                Project Name :{" "}
                <strong
                  onClick={() => viewProject(singleTrip.project)}
                  className={"hasAction"}
                >
                  {singleTrip.projectName}
                </strong>
              </p>
              <p>
                Trip Type : <strong>{singleTrip.type}</strong>
              </p>
              <p>
                Country : <strong>{singleTrip.countryName}</strong>
              </p>
              <p>
                Travel Type : <strong>{singleTrip.travelType}</strong>
              </p>
              <p>
                From Date :{" "}
                <strong>
                  {dayjs(singleTrip.fromDate).format("DD-MM-YYYY")}
                </strong>
              </p>
              <p>
                Return Date :{" "}
                <strong>{dayjs(singleTrip.toDate).format("DD-MM-YYYY")}</strong>
              </p>
             {singleTrip.returnFlightTicket!="0000-00-00"?<p>
                Return Flight Tickets :{" "}
                <strong>{dayjs(singleTrip.returnFlightTicket).format("DD-MM-YYYY")}</strong>
              </p>:null}

              {Number(singleTrip.onwardJourney)?<p>
                Onward Journey Days :{" "}
                <strong>{Number(singleTrip.onwardJourney)?singleTrip.onwardJourney:""}</strong>
              </p>:""}
              {Number(singleTrip.returnJourney)?<p>
                Return Journey Days :{" "}
                <strong>{singleTrip.returnJourney?singleTrip.returnJourney:""}</strong>
              </p>:""}
              {singleTrip.visaExpiry!="0000-00-00"?<p>
                Visa Expiry Date :{" "}
                <strong>
                  {singleTrip.visaExpiry?dayjs(singleTrip.visaExpiry).format("DD-MM-YYYY"):""}
                </strong>
              </p>:null}
              {singleTrip.insuranceExpiry!="0000-00-00"?<p>
                Insurance Expiry Date :{" "}
                <strong>
                  {singleTrip.insuranceExpiry?dayjs(singleTrip.insuranceExpiry).format("DD-MM-YYYY"):""}
                </strong>
              </p>:null}
              
              <p>
                Address: <strong>{singleTrip.address}</strong>
              </p>
              <p>
                PO Contact: <strong>{singleTrip?.poContact}</strong>
              </p>
              <p>
                Travel Equipment: <strong>{singleTrip.equipments}</strong>
              </p>
              <p>
                Travel Descritption : <strong>{singleTrip.description}</strong>
              </p>
              <p>
                Comment : <strong>{singleTrip.tripComment}</strong>
              </p>
              </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <p>
                <strong>Invoice</strong>
              </p>
              <p>
                Invoicing conditions :{" "}
                <strong>{singleTrip.invoiceCondition}</strong>
              </p>
              <p>
                Amount : <strong>{singleTrip.invoiceCurrency+" "+singleTrip.invoiceAmount}</strong>
              </p>
              <p>
                PO : <strong>{singleTrip.po}</strong>
              </p>
              <p>
                Invoice country : <strong>{singleTrip.invoiceCountryName}</strong>
              </p>
              <p>
                Invoice comments : <strong>{singleTrip.invoiceComment}</strong>
              </p>
              <p>
                <strong>Salary </strong>
              </p>
              <p>
                Salary conditions :{" "}
                <strong>{singleTrip.salaryCondition}</strong>
              </p>
              <p>
                Salary : <strong>{singleTrip.salaryCurrency+" "+singleTrip.salary}</strong>
              </p>
              <p>
                Daily allowance : <strong>{singleTrip.dailyAllowanceCurrency+" "+singleTrip.dailyAllowance}</strong>
              </p>
              <p>
                Driving allowance :{" "}
                <strong>{singleTrip.drivingAllowanceCurrency+" "+singleTrip.drivingAllowance}</strong>
              </p>
              <p>
                Overtime :{" "}

                <strong>{singleTrip.overTimeCurrency !== null ?singleTrip.overTimeCurrency:""+" "+singleTrip.overTime}</strong>
              </p>
              <p>
                Comments :<strong>{singleTrip.salaryComment}</strong>
              </p>
             
        
            </TabPanel>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default AddTrip;
